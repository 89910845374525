/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import { format, parse } from 'date-fns'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { FuneralInfo, ImageAndMessage, MessageOnly, QuoteAndMessage } from '../components/Templates'
import Clouds from '../components/Effects/Clouds'

class Obituary extends PureComponent {
  componentDidMount() {
    const clouds = new Clouds()
    clouds.generate()
    clouds.update()
  }

  render() {
    const { data } = this.props
    const { city, personName, obituaries } = data.person

    return (
      <>
        <SEO
          title={`Tributo a ${personName}`}
          keywords={[`Immortalis`, `Nota de falecimento de ${personName}`]}
        />
        <div className="relative w-100 h-auto overflow-hidden">
          <div id="viewport">
            <div id="world" />
          </div>
          <header className="tc pt4 pt5-ns pb3 pb4-m pb5-l flex flex-wrap items-center justify-center overflow-hidden relative">
            <div className="w-100 pb4">
              <p className="ma0 mb1 o-00 white-80">Tributo a</p>
              <h1 className="f2 f1-ns ma0 fw5 white lh-solid mb2">{personName}</h1>
              <small className="o-70 f5 f4-ns black-60">{city}</small>
            </div>
            <div className="addthis_inline_share_toolbox tc mt3" />
          </header>
        </div>
        <section className="tc nt4 z-1 relative">
          {obituaries.map((obituary, index) => {
            const date = new Date(obituary.dateCreated.seconds * 1000)
            return (
              <div key={index} className="w-100 mw6-ns center ph3 ph2-l mb2">
                {obituary.type === 'MessageOnly' && (
                  <div>
                    <MessageOnly message={obituary.message} sender={obituary.sender} />
                    <small className="tr w-100 mid-gray mt2 db">
                      Publicado em&nbsp;
                      {format(date, 'DD/MM/yyyy', { awareOfUnicodeTokens: true })}
                    </small>
                  </div>
                )}
                {obituary.type === 'ImageAndMessage' && (
                  <div>
                    <ImageAndMessage
                      message={obituary.message}
                      sender={obituary.sender}
                      alt={`Imagem de ${personName}`}
                      image={obituary.image}
                    />
                    <small className="tr w-100 mid-gray mt2 db">
                      Publicado em&nbsp;
                      {format(date, 'DD/MM/yyyy', { awareOfUnicodeTokens: true })}
                    </small>
                  </div>
                )}
                {obituary.type === 'QuoteAndMessage' && (
                  <div>
                    <QuoteAndMessage
                      message={obituary.message}
                      sender={obituary.sender}
                      quote={obituary.quote}
                      quoteBackgroundColor={obituary.quoteBackgroundColor}
                      quoteBackgroundImage={obituary.quoteBackgroundImage}
                    />
                    <small className="tr w-100 mid-gray mt2 db">
                      Publicado em&nbsp;
                      {format(date, 'DD/MM/yyyy', { awareOfUnicodeTokens: true })}
                    </small>
                  </div>
                )}
                {obituary.type === 'FuneralInfo' && (
                  <div>
                    <FuneralInfo
                      message={obituary.message}
                      sender={obituary.sender}
                      venueDateTime={obituary.venueDateTime}
                      venueName={obituary.venueName}
                      venueAddress={obituary.venueAddress}
                    />
                    <small className="tr w-100 mid-gray mt2 db">
                      Publicado em&nbsp;
                      {format(date, 'DD/MM/yyyy', { awareOfUnicodeTokens: true })}
                    </small>
                  </div>
                )}
              </div>
            )
          })}
        </section>
      </>
    )
  }
}

export default Obituary

export const pageQuery = graphql`
  query personQuery($id: String!) {
    person(id: { eq: $id }) {
      id
      available
      city
      personName
      obituaries {
        dateCreated {
          seconds
          nanoseconds
        }
        id
        image
        message
        quote
        quoteBackgroundColor
        quoteBackgroundImage
        sender
        type
        venueAddress
        venueDateTime
        venueName
      }
    }
  }
`
